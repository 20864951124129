import plainJoi from 'joi/lib/index';
import { CONSTS } from '../../utils';

export const joi = plainJoi.extend((baseJoi) => ({
  type: 'string',
  base: baseJoi.string(),
  rules: {
    routingChecksum: {
      validate(value, helpers, args, options) {
        const digits = value.split('').map(Number);
        const prefix = value.substring(0, 2);

        if (!CONSTS.ROUTE_NUMBER_PREFIXES.includes(prefix)) {
          return helpers.error('string.invalidChecksum', { v: value }, args, options);
        }

        const sum =
          3 * (digits[0] + digits[3] + digits[6]) +
          7 * (digits[1] + digits[4] + digits[7]) +
          (digits[2] + digits[5] + digits[8]);

        if (sum % 10 === 0) {
          return value;
        }

        return helpers.error('string.invalidChecksum', { v: value }, args, options);
      },
    },
  },
}));

export const schema = joi.object().keys({
  routingNumber: joi.string().routingChecksum().regex(CONSTS.REGEX.ROUTING_NUMBER).required(),
  accountNumber: joi.string().regex(CONSTS.REGEX.ACCOUNT_NUMBER).required(),
});
