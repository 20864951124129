import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';
import { schema as bankAccountSchema } from './deliveryMethodAch';
import { schema as paperCheckSchema } from './deliveryMethodCheck';
import { schema as cardAccountSchema } from './deliveryMethodCard';
import { email } from '../common/email';

export const schema = joi
  .object({
    deliveryType: joi.string().required().allow('ach', 'check', 'virtual', 'card', 'virtual-card').only(),
  })
  .when(joi.object({ deliveryType: 'virtual' }).unknown(), {
    then: joi.object({
      virtualAccount: joi
        .object()
        .required()
        .keys({
          email: email.max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
          phone: joi.string().allow(null, ''),
        }),
    }),
  })
  .when(joi.object({ deliveryType: 'virtual-card' }).unknown(), {
    then: joi.object({
      virtualCardAccount: joi
        .object()
        .required()
        .keys({
          accountEmail: email.max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
        }),
    }),
  })
  .when(joi.object({ deliveryType: 'ach' }).unknown(), {
    then: joi.object({
      bankAccount: bankAccountSchema,
    }),
  })
  .when(joi.object({ deliveryType: 'check' }).unknown(), {
    then: joi.object({
      paperCheck: paperCheckSchema,
    }),
  })
  .when(joi.object({ deliveryType: 'card' }).unknown(), {
    then: joi.object({
      cardAccount: cardAccountSchema,
    }),
  });
