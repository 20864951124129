export const CONSTS = {
  VALIDATIONS: {
    SHORT_STRING_LEN: 255,
    LONG_STRING_LEN: 2000,
    MAX_MONEY_VAL: 999999.99,
    DAYS_IN_YEAR: 365,
    SHORT_HANDLE_LEN: 3,
    LONG_HANDLE_LEN: 25,
    NAME_MIN_LEN: 2,
    COMPANY_MIN_LEN: 3,
    PHONE_MIN_LEN: 10,
  },
  TAX_ID_TYPE: {
    SSN: 'SSN',
    ITIN: 'ITIN',
    EIN: 'EIN',
  },
  ROUTE_NUMBER_PREFIXES: [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '80',
  ],
  REGEX: {
    ZIP_CODE: /^\d{5}(?:[-]\d{4})?$/,
    ACCOUNT_NUMBER: /^[0-9]{6,17}$/,
    ROUTING_NUMBER: /^[0-9]{9}$/,
    HANDLE: /^\w+$/,
    TAX_ID_NUMBER: /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/,
    OCCURRENCES_WEEKLY_RANGE: /^([1-9]|[12345][0-9]|6[0])$/,
    OCCURRENCES_MONTHLY_RANGE: /^([1-9]|[1][0-9]|2[0-4])$/,
    USA_PHONE: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    AT_LEAST_ONE_ALPHANUM: /^.*[a-zA-Z0-9]+.*$/,
    NAME_LEGAL_CHARS_ONLY: /^[a-zA-Z]+(?:[\w -]*[a-zA-Z]+)*$/,
  },
  US_STATES: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
};
