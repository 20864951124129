import isObject from 'lodash/isObject';
import { ObjectOf } from './types';

/**
 * Checks that an object has a specific key.
 * This is a helper function for the `in` operator, which also provides type safety for `o`.
 */
export function hasKey<T extends ObjectOf<T>, K extends AnyKey>(o: T, k: K): o is T & Record<K, unknown> {
  return isObject(o) && k in o;
}

export function hasKeys<T extends ObjectOf<T>>(
  o: T,
  ...keys: ReadonlyArray<keyof T>
): o is T & Record<keyof T, unknown> {
  return keys.every((key) => hasKey(o, key));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is fine, only way to really get all types of keys of any object.
type AnyKey = keyof any;
