import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';

export const schema = joi.object().keys({
  handle: joi
    .string()
    .regex(CONSTS.REGEX.HANDLE)
    .min(CONSTS.VALIDATIONS.SHORT_HANDLE_LEN)
    .max(CONSTS.VALIDATIONS.LONG_HANDLE_LEN)
    .uri({ relativeOnly: true })
    .required(),
});
