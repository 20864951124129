import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';
import { email } from '../common/email';

export const schema = joi.object().keys({
  firstName: joi
    .string()
    .pattern(CONSTS.REGEX.NAME_LEGAL_CHARS_ONLY)
    .min(CONSTS.VALIDATIONS.NAME_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .required(),
  lastName: joi
    .string()
    .pattern(CONSTS.REGEX.NAME_LEGAL_CHARS_ONLY)
    .min(CONSTS.VALIDATIONS.NAME_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .required(),
  email: email.required(),
  requireApproval: joi.boolean(),
  approvalAmountThreshold: joi.when('requireApproval', {
    is: true,
    then: joi.number().required().min(0),
  }),
});
