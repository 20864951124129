import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';

const now = new Date();

export const schema = joi.object().keys({
  companyName: joi
    .string()
    .trim()
    .min(CONSTS.VALIDATIONS.COMPANY_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .required(),
  legalCompanyName: joi
    .string()
    .trim()
    .min(CONSTS.VALIDATIONS.COMPANY_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .required(),
  phone: joi
    .string()
    .custom((value) => (value ? value.replace(/\D/g, '') : value))
    .min(10)
    .required(),
  contactFirstName: joi
    .string()
    .trim()
    .min(CONSTS.VALIDATIONS.NAME_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .regex(CONSTS.REGEX.NAME_LEGAL_CHARS_ONLY)
    .required(),
  contactLastName: joi
    .string()
    .trim()
    .min(CONSTS.VALIDATIONS.NAME_MIN_LEN)
    .max(CONSTS.VALIDATIONS.SHORT_STRING_LEN)
    .regex(CONSTS.REGEX.NAME_LEGAL_CHARS_ONLY)
    .required(),
  yearOfIncorporation: joi.number().allow(null).integer().positive().min(1000).max(now.getFullYear()),
  businessType: joi.string().required(),
  taxId: joi.string().required().regex(CONSTS.REGEX.TAX_ID_NUMBER),
  taxIdType: joi.string().required().valid(CONSTS.TAX_ID_TYPE.SSN, CONSTS.TAX_ID_TYPE.ITIN, CONSTS.TAX_ID_TYPE.EIN),
  zipCode: joi.string().regex(CONSTS.REGEX.ZIP_CODE).required(),
  legalZipCode: joi.string().regex(CONSTS.REGEX.ZIP_CODE).required(),
  googlePlaceId: joi.string().required(),
  legalGooglePlaceId: joi.string().required(),
  addressLine1: joi.string().required(),
  legalAddressLine1: joi.string().required(),
  city: joi.string().required(),
  legalCity: joi.string().required(),
  state: joi.string().required(),
  legalState: joi.string().required(),
});
