import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';

const startOfTheDay = new Date();
startOfTheDay.setHours(0, 0, 0, 0);

export const MAX_LENGTHS = {
  invoiceNumber: CONSTS.VALIDATIONS.SHORT_STRING_LEN,
  note: CONSTS.VALIDATIONS.LONG_STRING_LEN,
};

export const schema = joi.object().keys({
  vendorId: joi.number().required(),
  intuitAccountId: joi.string(),
  totalAmount: joi.number().greater(0).max(CONSTS.VALIDATIONS.MAX_MONEY_VAL).required(),
  invoiceNumber: joi.string().allow(null).allow('').max(MAX_LENGTHS.invoiceNumber),
  /* https://trello.com/c/gT6RXFWD/1585-remove-terms-and-bill-date-fields-from-add-edit-a-bill
  invoiceDate: joi.date().required(),
  terms: joi.number().min(0).max(CONSTS.VALIDATIONS.DAYS_IN_YEAR).required(), */
  dueDate: joi.when(joi.ref('$isRecurringBill'), {
    is: joi.boolean().valid(true),
    then: joi.date().min(startOfTheDay).required(),
    otherwise: joi.date().required(),
  }),
  note: joi.string().allow(null).allow('').max(MAX_LENGTHS.note),
  frequency: joi.string(),
  occurrences: joi.when('frequency', {
    is: 'weekly',
    then: joi.string().regex(CONSTS.REGEX.OCCURRENCES_WEEKLY_RANGE),
    otherwise: joi.string().regex(CONSTS.REGEX.OCCURRENCES_MONTHLY_RANGE),
  }),
});

// TODO: add line items
