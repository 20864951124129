import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';
import { email } from '../common/email';

export const schema = joi.object().keys({
  customerId: joi.number().required(),
  intuitAccountId: joi.when(joi.ref('$isIntuitLinked'), {
    is: joi.boolean().valid(true),
    then: joi.string().required(),
    otherwise: joi.string().allow(null),
  }),
  totalAmount: joi.number().greater(0).max(CONSTS.VALIDATIONS.MAX_MONEY_VAL).required(),
  invoiceNumber: joi.string().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  dueDate: joi.date().required(),
  customerNote: joi.string().allow(null).allow('').max(CONSTS.VALIDATIONS.LONG_STRING_LEN),
  customerEmail: email.trim().allow(null, '').max(CONSTS.VALIDATIONS.SHORT_STRING_LEN),
});
