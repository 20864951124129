import * as userRegistration from './user/registration';
import * as user from './user/user';
import * as vendorUserRegistration from './vendor/registration';
import * as bill from './bill/bill';
import * as paymentRequest from './payment-request/payment-request';
import * as vendor from './vendor/vendor';
import * as guest from './guest/guest';
import * as deliveryMethod from './vendor/deliveryMethod';
import * as deliveryMethodAch from './vendor/deliveryMethodAch';
import * as deliveryMethodCheck from './vendor/deliveryMethodCheck';
import * as vendorAddress from './vendor/vendorAddress';
import * as companyInfo from './organization/company-info';
import * as fundingSourceBank from './organization/funding-source-bank';
import * as fundingSourcePlaidBank from './funding-source/funding-source-plaid-bank';
import * as fundingSourceCard from './funding-source/funding-source-card';
import * as microDepositsVerification from './funding-source/micro-deposits-verification';
import * as invitation from './invitation/invitation';
import * as userOrganization from './user/userOrganization';
import * as virtualCardCandidate from './virtual-card/candidate-info';

export const schemas = {
  userRegistration: userRegistration.deprecatedSchema,
  userRegistrationStrong: userRegistration.schema,
  user: user.schema,
  vendorUserRegistration: vendorUserRegistration.schema,
  bill: bill.schema,
  paymentRequest: paymentRequest.schema,
  vendor: vendor.schema,
  guest: guest.schema,
  deliveryMethod: deliveryMethod.schema,
  deliveryMethodAch: deliveryMethodAch.schema,
  deliveryMethodCheck: deliveryMethodCheck.schema,
  vendorAddress: vendorAddress.schema,
  companyInfo: companyInfo.schema,
  fundingSourceBank: fundingSourceBank.schema,
  fundingSourcePlaidBank: fundingSourcePlaidBank.schema,
  fundingSourceCard: fundingSourceCard.schema,
  microDepositsVerification: microDepositsVerification.schema,
  invitation: invitation.schema,
  userOrganization: userOrganization.schema,
  virtualCardCandidate: virtualCardCandidate.schema,
};

export const MAX_LENGTHS = {
  bill: bill.MAX_LENGTHS,
  vendor: vendor.MAX_LENGTHS,
  deliveryMethodCheck: deliveryMethodCheck.MAX_LENGTHS,
};
