import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';

function calculateAgeBoundaries() {
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const oneHundredTwentyYearsAgo = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate());
  return { eighteenYearsAgo, oneHundredTwentyYearsAgo };
}

const { eighteenYearsAgo, oneHundredTwentyYearsAgo } = calculateAgeBoundaries();

export const schema = joi.object().keys({
  firstName: joi.string().trim().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  lastName: joi.string().trim().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  dateOfBirth: joi.date().max(eighteenYearsAgo).min(oneHundredTwentyYearsAgo).required(),
});
