import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';
import { email } from '../common/email';

export const MAX_LENGTHS = {
  companyName: CONSTS.VALIDATIONS.SHORT_STRING_LEN,
  contactEmail: CONSTS.VALIDATIONS.SHORT_STRING_LEN,
};

export const schema = joi.object().keys({
  companyName: joi.string().trim().max(MAX_LENGTHS.companyName).required(),
  contactEmail: email.allow(null, '').max(MAX_LENGTHS.contactEmail).trim(),
  contactPhone: joi.string().allow(null).allow(''),
  billerId: joi.string().optional(),
  directoryType: joi.string().optional().allow('biller', 'business'),
  directoryId: joi.string().optional(),
  accountIdentifier: joi
    .when('billerId', {
      is: joi.string().required(),
      then: joi.string().trim().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
      otherwise: joi.string().valid(null, ''),
    })
    .when('directoryType', {
      is: joi.string().valid('biller'),
      then: joi.string().trim().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
      otherwise: joi.string().valid(null, ''),
    }),
});
