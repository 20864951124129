import plainJoi from 'joi/lib/index';
import { zxcvbn, zxcvbnOptions, FeedbackType } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import { email } from '../common/email';

const DEFAULT_MIN_SCORE = 1;

const zxcvbnOptionsValues = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  maxLength: 50,
};

zxcvbnOptions.setOptions(zxcvbnOptionsValues);

function getErrFromFeedback(feedback: FeedbackType) {
  const warningToError = {
    'Repeated character patterns like "abcabcabc" are easy to guess.': 'noRepeats',
    'This is a frequently used password.': 'tooCommon',
    'This is a heavily used password.': 'tooCommon',
  } as const;

  const suggestionToError = {
    'Add more words that are less common.': 'addMoreWords',
    'Avoid years that are associated with you.': 'avoidAssociatedDatesAndYears',
    'Avoid dates and years that are associated with you.': 'avoidAssociatedDatesAndYears',
    'Avoid repeated words and characters.': 'avoidRepeats',
    'Avoid common character sequences.': 'avoidSequences',
    'Avoid recent years.': 'avoidRecentYears',

    'Use multiple words, but avoid common phrases.': 'generic',
    'You can create strong passwords without using symbols, numbers, or uppercase letters.': 'generic',
    'Use longer keyboard patterns and change typing direction multiple times.': 'generic',
    'Capitalize more than the first letter.': 'generic',
    'Capitalize some, but not all letters.': 'generic',
    'Avoid reversed spellings of common words.': 'generic',
    "Avoid predictable letter substitutions like '@' for 'a'.": 'generic',
  } as const;

  if (feedback?.warning && feedback?.warning.length > 0) {
    return warningToError[feedback.warning as keyof typeof warningToError] ?? 'tooCommon';
  }

  if (feedback.suggestions.length === 0) {
    return 'generic';
  }

  const suggestion = feedback.suggestions[0];

  return suggestionToError[suggestion as keyof typeof suggestionToError] ?? 'generic';
}

const joi = plainJoi.extend((baseJoi) => ({
  // This is mostly taken from https://github.com/iilei/joi-zxcvbn/blob/master/index.js
  base: baseJoi.string(),
  type: 'string',
  rules: {
    zxcvbn: {
      method(minScore) {
        return this.$_addRule({ name: 'zxcvbn', args: { minScore } });
      },
      args: [
        {
          name: 'minScore',
          assert: baseJoi.number().integer().min(0).max(4),
        },
      ],
      validate(value, helpers, args, options) {
        const min = typeof args.minScore === 'number' ? args.minScore : DEFAULT_MIN_SCORE;
        const result = zxcvbn(value || '');
        const { feedback, score } = result;

        if (score >= min) {
          return value;
        }

        const err = getErrFromFeedback(feedback);

        return helpers.error('string.zxcvbn', { v: value, err }, helpers.state, options);
      },
    },
  },
}));

export function getPasswordScore(password: string) {
  return zxcvbn(password).score;
}

export const deprecatedSchema = joi.object().keys({
  email: email.required(),
  password: joi.string().zxcvbn(1).min(8).required(),
  currentPassword: joi.string().required(),
  confirmedPassword: joi.any().valid(joi.ref('password')).required(),
});

export const schema = joi.object().keys({
  email: email.required(),
  password: joi.string().zxcvbn(2).min(8).required(),
  currentPassword: joi.string().required(),
  confirmedPassword: joi.any().valid(joi.ref('password')).required(),
});
