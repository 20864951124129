import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';
import { email } from '../common/email';

export const schema = joi.object().keys({
  totalAmount: joi.number().greater(0).max(CONSTS.VALIDATIONS.MAX_MONEY_VAL).required(),
  invoiceNumber: joi.string().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  businessName: joi.string().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  email: email.required(),
});
