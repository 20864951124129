import { CONSTS } from '../../utils';
import { email } from '../common/email';
import { joi } from '../vendor/deliveryMethodAch';

export const schema = joi.object().keys({
  firstAndLastName: joi.string().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  email: email.required(),
  phone: joi.string().required(),
  phoneExt: joi.string().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN),
  companyName: joi.string().trim().max(CONSTS.VALIDATIONS.SHORT_STRING_LEN).required(),
  googlePlaceId: joi.string().required(),
  routingNumber: joi.string().routingChecksum().regex(CONSTS.REGEX.ROUTING_NUMBER),
  accountNumber: joi.string().regex(CONSTS.REGEX.ACCOUNT_NUMBER),
  taxId: joi.string().regex(CONSTS.REGEX.TAX_ID_NUMBER),
  taxIdType: joi.string().valid(CONSTS.TAX_ID_TYPE.SSN, CONSTS.TAX_ID_TYPE.ITIN, CONSTS.TAX_ID_TYPE.EIN),
});
