import joi from 'joi/lib/index';
import { CONSTS } from '../../utils';

export const MAX_LENGTHS = {
  printName: CONSTS.VALIDATIONS.SHORT_STRING_LEN,
};

export const schema = joi.object().keys({
  printName: joi.string().max(MAX_LENGTHS.printName).regex(CONSTS.REGEX.AT_LEAST_ONE_ALPHANUM).required(),
  googlePlaceId: joi.string().required(),
  addressLine1: joi.string().trim().min(CONSTS.VALIDATIONS.SHORT_HANDLE_LEN).required(),
  city: joi.string().required(),
  state: joi
    .string()
    .valid(...CONSTS.US_STATES)
    .required(),
  zipCode: joi.string().regex(CONSTS.REGEX.ZIP_CODE).required(),
});
